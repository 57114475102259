import React from 'react'
import styled from '@emotion/styled'
import { Link } from "gatsby"
import { PropTypes } from 'prop-types'
import { mediaQuery } from "./shared-styles"
import Img from "gatsby-image"

import borderHorizontal from '../images/borderHorizontal.svg';

const Wrapper = styled(Link)({
  position: 'relative',
  width: '100%',
  height: '50%',
  marginTop: '20px',
  marginBottom: '20px',
});

const Image = styled(Img)({
  width: '100%',
  height: '100%',
  [mediaQuery.medium]: ({
    width: 'calc(100% - 40px)',
    margin: '0px 0 0 20px',
  })
});

const Overlay = styled('div')({
  width: 'calc(100% - 40px)',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0)',
  margin: '0px 0 0 20px',
  transition: '.2s opacity ease-in-out',
  position: 'absolute',
  top: 0,
  left: 0,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  }
});

const TextBox = styled('div')({
  position: 'absolute',
  left: '20px',
  bottom: '20px',
  height: '60px',
  opacity: 0.85,
  backgroundColor: 'white',
  overflow: 'hidden',
});

const Text = styled('div')({
  height: '100%',
  color: 'black',
  fontWeight: 200,
  fontSize: '1.5rem',
  padding: '12px 20px',
});

const BorderBottom = styled('div')({
  width: '100%',
  height: '20px',
  backgroundImage: `url('${borderHorizontal}')`,
  backgroundRepeat: 'repeat-x',
  position: 'absolute',
  bottom: -39,
  left: 0,
});

const ListItem = ({ image, link, name, borderRight, borderBottom, ...props }) => (
  <Wrapper {...props} to={link}>
    <Image fluid={image} />
    <Overlay />
    <TextBox>
      <Text>{name}</Text>
    </TextBox>
    {borderBottom && <BorderBottom /> }
  </Wrapper>
)

ListItem.propTypes = {
  image: PropTypes.string.isRequired,
}

export default ListItem
