import Layout from '../components/layout'
import ListItem from '../components/list-item'
import React from 'react';
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet';
import { graphql } from "gatsby"

const Wrapper = styled('div')({
  display: 'flex',
  flexFlow: 'row wrap',
  width: '100%',
  height: '100%',
});

export const query = graphql`
  query {
    recepce8: file(relativePath: { eq: "recepce8/recepce8-1.jpg" }) { ...fluidImage }
    bytMuchova: file(relativePath: { eq: "byt-muchova/kr-1.jpg" }) { ...fluidImage }
    bytZubateho: file(relativePath: { eq: "byt-zubateho/zubateho-1.jpg" }) { ...fluidImage }
    horskeLazne: file(relativePath: { eq: "horske-lazne/roznov-1.jpg" }) { ...fluidImage }
    ledarnyBranik: file(relativePath: { eq: "ledarny-branik/branik-1.jpg" }) { ...fluidImage }
    museumSudet: file(relativePath: { eq: "museum-sudet/sudety-1.jpg" }) { ...fluidImage }
    sirneLazne: file(relativePath: { eq: "sirne-lazne/lazne-1.jpg" }) { ...fluidImage }
    vstupVytah: file(relativePath: { eq: "vstup-vytah/portal-1.jpg" }) { ...fluidImage }
    kavarna: file(relativePath: { eq: "kavarna/karto-1.jpg" }) { ...fluidImage }
    atelier: file(relativePath: { eq: "atelier/atelier-1.jpg" }) { ...fluidImage }
  }
`

const DraftsPage = ({ data }) => (
  <Layout>
    <Helmet>
      <title>Návrhy</title>
      <meta name="description" content="Ing. arch. Marek Svoboda architektura 3D realizace interiéry byty exteriéry" />
    </Helmet>
    <Wrapper>
      <ListItem image={data.sirneLazne.childImageSharp.fluid} link='/navrhy/sirne-lazne' name='Sirné lázně' borderBottom />
      <ListItem image={data.atelier.childImageSharp.fluid} link='/navrhy/atelier' name='Ateliér' borderBottom />
      <ListItem image={data.kavarna.childImageSharp.fluid} link='/navrhy/kavarna' name='Kavárna' borderBottom />
      <ListItem image={data.vstupVytah.childImageSharp.fluid} link='/navrhy/vstup-vytah' name='Vstup výtah' borderBottom />
      <ListItem image={data.museumSudet.childImageSharp.fluid} link='/navrhy/museum-sudet' name='Museum Sudet' borderBottom />
      <ListItem image={data.bytMuchova.childImageSharp.fluid} link='/navrhy/byt-muchova' name='Byt Muchova' borderBottom />
      <ListItem image={data.bytZubateho.childImageSharp.fluid} link='/navrhy/byt-zubateho' name='Byt Zubatého' borderBottom />
      <ListItem image={data.horskeLazne.childImageSharp.fluid} link='/navrhy/horske-lazne' name='Horské lázně' borderBottom />
      <ListItem image={data.ledarnyBranik.childImageSharp.fluid} link='/navrhy/ledarny-branik' name='Ledárny Braník' />
    </Wrapper>
  </Layout>
)

export default DraftsPage
